import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueMeta from 'vue-meta'
Vue.use( VueMeta, {
	keyName: 'metaInfo',
	attribute: 'data-vue-meta',
	ssrAttribute: 'data-vue-meta-server-rendered',
	tagIDKeyName: 'vmid',
})

//routes
import frontendRoutes from './frontend';
import adminRoutes from './admin';

// session components
const SignUp = () => import('Views/session/SignUp');
const Login = () => import('Views/session/Login');
const ForgotPassword = () => import('Views/session/ForgotPassword');
const ActivateAccount = () => import('Views/session/ActivateAccount');
const ActivatePassword = () => import('Views/session/ActivatePassword');
const ComingSoon = () => import('Views/session/ComingSoon');
const Error404 = () => import('Views/session/Error404');

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		frontendRoutes,
		adminRoutes,
		{
			path: '/session/sign-up',
			component: SignUp,
			name: 'SignUp',
			meta: {
				title: 'Signup'
				// breadcrumb: 'Session / Sign Up'
			}
		},
		{
			path: '/session/login',
			component: Login,
			name: 'Login',
			meta: {
				title: 'Login'
				// breadcrumb: 'Session / Login'
			},
			props: true
		},
		{
			path: '/session/forgot-password',
			component: ForgotPassword,
			name: 'ForgotPassword',
			meta: {
				title: 'Forgot Password'
				// breadcrumb: 'Session / Forgot Password'
			}
		},
		{
			path: '/session/activate-account',
			component: ActivateAccount,
			name: 'ActivateAccount',
			meta: {
				title: 'Activate account'
				// breadcrumb: 'Session / Forgot Password'
			}
		},
		{
			path: '/session/activate-password',
			component: ActivatePassword,
			name: 'ActivatePassword',
			meta: {
				title: 'Activate change password'
				// breadcrumb: 'Session / Forgot Password'
			}
		},
		{
			path: '/session/coming-soon',
			component: ComingSoon,
			name: 'ComingSoon',
			meta: {
				title: 'Coming Soon'
				// breadcrumb: 'Session / Reset Password'
			}
		},
		{
			path: '/*',
			component: Error404,
			name: 'Error404',
			meta: {
				title: 'Error 404'
			}
		}
	],

	scrollBehavior( to, from, savedPosition ) {
		if ( to.hash ) {
			switch ( to.hash) {
				case '#contacto':
					return goTo( to.hash, { offset: 50 } )
				case '#actualidad':
					return goTo( to.hash, { offset: 100 } )

				default:
					return goTo( to.hash, { offset: 0 } )
			}

		} else if ( savedPosition ) {
			return goTo(savedPosition.y);
		} else {
			return goTo(0);
		}
	}
})

// router gards
router.beforeEach( ( to, from, next ) => {
	NProgress.start()

	// Gets connection country
	let country = {}
  if ( !localStorage.getItem( 'selectedCountry' ) ) {
		axios
			.get('get-location' )
			.then( ( response ) => {
				country = response.data
				localStorage.setItem( 'selectedCountry', JSON.stringify( response.data ) )

				// Validate authentication
				if ( to.path != 'session/login' && to.meta.requiresAuth) {
					const authUser = JSON.parse(localStorage.getItem('vue-session-key')) // Gets user information
					let counter = 0
					let assignedRoles = []
					if ( authUser && authUser.roles) {
						authUser.roles.forEach( role => {
							assignedRoles.push( role.name )
						} )
					}
					if ( authUser && authUser.tokenSession ) { // If token exist and is required
						to.meta.requiredRoles.forEach(role =>{
							if ( assignedRoles.includes( role ) ) counter++
						})
						if ( counter > 0 ) {
							next( { props: { 'country': country, 'access': 'permitted' } } )
						} else {
							next( { path: from.path, props: { 'country': country, 'access': 'denied' } } )
						}
					} else { // If token doesn't exist but is required
						next( { name: 'Login', query: { 'redirect': to.path} } )
					}
				} else {
					next( { props: { 'country': country }})
				}
			} )
	} else {
    country = localStorage.getItem( 'selectedCountry' )
		// Validate authentication
		if ( to.path != 'session/login' && to.meta.requiresAuth) {
			const authUser = JSON.parse(localStorage.getItem('vue-session-key')) // Gets user information
			let counter = 0
			let assignedRoles = []
			if ( authUser && authUser.roles) {
				authUser.roles.forEach( role => {
					assignedRoles.push( role.name )
				} )
			}
			if ( authUser && authUser.tokenSession ) { // If token exist and is required
				to.meta.requiredRoles.forEach(role =>{
					if ( assignedRoles.includes( role ) ) counter++
				})
				if ( counter > 0 ) {
					next( { props: { 'country': country, 'access': 'permitted' } } )
				} else {
					next( { path: from.path, props: { 'country': country, 'access': 'denied' } } )
				}
			} else { // If token doesn't exist but is required
				next( { name: 'Login', query: { 'redirect': to.path} } )
			}
		} else {
			next( { props: { 'country': country }})
		}
  }
} )

router.afterEach( () => {
	NProgress.done()
} )

export default router
