import Vue from 'vue'
import VueSession from 'vue-session'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import axios from './api'
import router from './router' // router
import vuetify from './plugins/vuetify'
import VModal from 'vue-js-modal'
import DatetimePicker from 'vuetify-datetime-picker'
import Multiselect from 'vue-multiselect'
import { VueMaskDirective } from 'v-mask'
import Vue2Editor from "vue2-editor"
import AOS from 'aos'
import Carousel3d from 'vue-carousel-3d'
import VueLazyLoad from 'vue-lazyload'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VideoBackground from 'vue-responsive-video-background-player'
import mixitup from 'mixitup'
import 'bootstrap'
import './lib/CustomScript' // include script file
import './lib/CustomCss' // include all css files

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'vue-js-modal/dist/styles.css'
// import 'vue-multiselect/dist/vue-multiselect.min.css's
// import 'aos/dist/aos.css'
// import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
// import 'swiper/css/swiper.css'
// import 'sweetalert2/dist/sweetalert2.min.css'

Object.defineProperty(Vue.prototype, 'mixitup', {
	value: mixitup
})

Vue.component('video-background', VideoBackground);

Vue.use(VueAwesomeSwiper)
Vue.use( VueSession, { persist : true } )
Vue.use(DatetimePicker)
Vue.use(Vue2Editor)
Vue.use(Carousel3d);
Vue.use(VueLazyLoad)
Vue.component('multiselect', Multiselect)
Vue.use(VModal, {
	componentName: 'modal',
	dynamicDefault: {
		adaptive: true,
		resizable: true,
		height: 'auto',
	},
})
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk', // Add here your google map api key
	},
})
Vue.directive( 'mask', VueMaskDirective );

const options = {
	confirmButtonColor: '#003a56',
	cancelButtonColor: '#ff7674',
}
Vue.use(VueSweetalert2, options)

window.$ = require('jquery')
window.JQuery = require('jquery')
window.swal = VueSweetalert2

axios.defaults.timeout = 300000
axios.defaults.baseURL = process.env.VUE_APP_API_DIRECTORY

// Add a response interceptor
axios.interceptors.response.use(
	response => {
		if ( response.status === 200 || response.status === 201 || response.status === 204 ) {
			return Promise.resolve( response );
		} else {
			return Promise.reject( response );
		}
	},
	error => {
		if ( error.response && error.response.status ) {
			switch ( error.response.status ) {
				case 401:
					if ( error.response.config.url == axios.defaults.baseURL + 'auth/login' ) {
						Vue.swal( {
							icon: 'error',
							title: 'Datos inválidos',
							html: 'La combinación de correo electrónico y contraseña es incorrecta',
							showCancelButton: false,
							showConfirmButton: true,
						} )
					}

					if ( localStorage.getItem( 'vue-session-key' ) && JSON.parse( localStorage.getItem( 'vue-session-key' ) ).tokenSession) {
						Vue.swal( {
							icon: 'error',
							title: `Acceso restringido`,
							html: `Tu sesión ha caducado o no tienes permisos suficientes para continuar.`,
							showCancelButton: false,
							showConfirmButton: true,
						} )
						localStorage.removeItem( 'vue-session-key' )
						router.push( '/' )
					}

					throw new Error( error )

				case 422:
					let contentMessage = ''
					let arrayOfErrors = []
					if ( error.response.data.errors) {
						contentMessage = '<p>Por favor, verifique la información suministrada e intente nuevamente</p><dl style="text-align: justify">'
						arrayOfErrors = Object.entries(error.response.data.errors)
						arrayOfErrors.forEach(error => {
							contentMessage = contentMessage + '<dt style="text-transform: capitalize">' + error[ 0 ] + '</dt>'
							error[1].forEach(item => {
								contentMessage = contentMessage + '<dd>- ' + item + '</dd >'
							})
						})
						contentMessage = contentMessage + '</dl>'
					} else {
						contentMessage = 'La información sumistrada es inválida'
					}
					Vue.swal( {
						icon: 'error',
						title: error.response.data.message ? error.response.data.message : 'Ocurrió un error',
						html: contentMessage,
						showCancelButton: false,
						showConfirmButton: true,
					} )
					throw new Error( error )
				}
		}
		return Promise.reject( error )
	}
)

window.axios = axios

Vue.config.productionTip = false

new Vue({
	router,
	render: (h) => h(App),
	vuetify,
	components: { App },
	mounted() {
    AOS.init()
  },
}).$mount('#app')
