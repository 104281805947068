const FrontendPanel = () => import('Layout/frontendPanel/FrontendPanel')

// dashboard components
const DashboardOne = () => import( 'Views/dashboard/DashboardOne' )
const CompanyDetail = () => import( 'Views/pages/CompanyDetail' )
const CompanyType = () => import( 'Views/pages/CompanyType' )
const MagazineList = () => import( 'Views/pages/MagazineList' )
const Publications = () => import( 'Views/pages/Publications' )
const PublicationDetail = () => import( 'Views/pages/PublicationDetail' )
const WikiFranchises = () => import( 'Views/pages/WikiFranchises' )
const Woki = () => import( 'Views/pages/Woki' )

export default {
  path: '/',
  name: 'FrontendPanel',
	component: FrontendPanel,
	redirect: '/',
	meta: {
		title: 'FrontendPanel',
		requiresAuth: false,
	},
	children: [
		{
			path: '/',
			name: 'Inicio',
			component: DashboardOne,
			meta: {
        title: 'Directorio de Franquicias',
        requiresAuth: false,
				sitemap: {
					lastmod: new Date(),
					priority: 1.0,
					changefreq: 'daily',
				}
			},
		},
		{
			path: '/franquicias',
			name: 'Franquicias',
			component: CompanyType,
			meta: {
				title: 'Franquicias',
				requiresAuth: false,
			},
			props: {
				companyType: 'Franquicias'
			}
		},
		{
			path: '/franquicias-master',
			name: 'Franquicias Master',
			component: CompanyType,
			meta: {
				title: 'Franquicias Master',
				requiresAuth: false,
			},
			props: {
				companyType: 'Franquicias Master'
			}
		},
		{
			path: '/asociaciones-franquicias',
			name: 'Asociaciones de franquicias',
			component: CompanyType,
			meta: {
				title: 'Asociaciones de Franquicias',
				requiresAuth: false,
			},
			props: {
				companyType: 'Asociaciones de Franquicias'
			}
		},
		{
			path: '/consultores',
			name: 'Consultores',
			component: CompanyType,
			meta: {
				title: 'Empresas',
				requiresAuth: false,
			},
			props: {
				companyType: 'Consultores'
			}
		},
		{
			path: '/proveedores',
			name: 'Proveedores',
			component: CompanyType,
			meta: {
				title: 'Empresas',
				requiresAuth: false,
			},
			props: {
				companyType: 'Proveedores'
			}
		},
		{
			path: '/oferta-inmobiliaria',
			name: 'Oferta Inmobiliaria',
			component: CompanyType,
			meta: {
				title: 'Empresas',
				requiresAuth: false,
			},
			props: {
				companyType: 'Oferta Inmobiliaria'
			}
		},
		// {
		// 	path: '/sufranquicia',
		// 	name: 'Ediciones de la revista',
		// 	component: MagazineList,
		// 	meta: {
		// 		title: 'Revistas',
		// 		requiresAuth: false,
		// 	},
		// 	props: {
		// 		companyType: 'Ediciones de la revista'
		// 	}
		// },
		{
			path: '/empresa/:slug',
			name: 'CompanyDetail',
			component: CompanyDetail,
			meta: {
            title: 'Detalles de la Franquicia',
            requiresAuth: false,
			},
		},
		{
			path: '/blog',
			name: 'Blog',
			component: Publications,
			meta: {
				title: 'Blog',
				requiresAuth: false,
			},
			props: { 'publicationType': 'blogs' }
		},
		{
			path: '/noticias',
			name: 'News',
			component: Publications,
			meta: {
				title: 'Noticias',
				requiresAuth: false,
			},
			props: { 'publicationType': 'news' }
		},
		{
			path: '/eventos',
			name: 'Events',
			component: Publications,
			meta: {
				title: 'Eventos',
				requiresAuth: false,
			},
			props: { 'publicationType': 'events' }
		},
		{
			path: '/publicaciones/:slug',
			name: 'Publications',
			component: PublicationDetail,
			meta: {
				title: 'Publicaciones',
				requiresAuth: false,
			},
			props: { 'publicationType': 'events' }
		},
		{
			path: '/WikiFranquicias',
			name: 'WikiFranchises',
			component: WikiFranchises,
			meta: {
				title: 'Wiki Franquicias',
				requiresAuth: false,
			},
		},
		{
			path: '/Woki',
			name: 'Woki',
			component: Woki,
			meta: {
				title: 'Woki',
				requiresAuth: false,
			},
		},
	],
}


